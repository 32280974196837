import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import "./PageNavigation.scss";

const PageNavigation = ({ data }) => (
  <nav
    className="page-navigation is-transparent"
    itemScope=""
    itemType="http://schema.org/SiteNavigationElement"
  >
    {data.allWordpressPage.edges.map((edge) => (
      <Link
        className="page-navigation-item page_item"
        to={`/${edge.node.slug}`}
        key={edge.node.slug}
      >
        {edge.node.title.rendered}
      </Link>
    ))}
  </nav>
);

const PageNavigationWithQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(sort: { fields: menu_order }, limit: 5) {
          edges {
            node {
              title {
                rendered
              }
              slug
            }
          }
        }
      }
    `}
    render={(data) => <PageNavigation data={data} />}
  />
);

export { PageNavigationWithQuery };
