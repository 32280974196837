import React from "react";
import "./Footer.scss";

const Footer = () => (
  <footer
    className="footer"
    role="contentinfo"
    itemScope=""
    itemType="http://schema.org/WPFooter"
  >
    <div id="inner-footer" className="wrap cf">
      <p className="source-org copyright">
        &#169; 2021 Looseideas.co.uk – Ryan Mitchell.
      </p>
    </div>
  </footer>
);

export { Footer };
