import React from "react";
import { Link } from "gatsby";

import "./Logo.scss";

const LogoPWrapper = ({ children, ...rest }) => <p {...rest}>{children}</p>;

const LogoH1Wrapper = ({ children, ...rest }) => <h1 {...rest}>{children}</h1>;

const Logo = ({ isHomepage = false }) => {
  const Tag = isHomepage ? LogoH1Wrapper : LogoPWrapper;

  return (
    <Tag
      id="logo"
      className="h1"
      itemScope=""
      itemType="http://schema.org/Organization"
    >
      <Link to="/" rel="nofollow">
        Looseideas.co.uk – Ryan Mitchell
      </Link>
    </Tag>
  );
};

export { Logo };
