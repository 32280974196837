import React from "react";
import Logo from "../Logo";
import PageNavigation from "../PageNavigation";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { HtmlHeader } from "./HtmlHeader";

import "../../scss/style.scss";
import "./Layout.scss";

const Layout = ({ isHomepage = false, description, children }) => (
  <div>
    <HtmlHeader description={description} />
    <header
      className="header"
      itemScope=""
      itemType="http://schema.org/WPHeader"
    >
      <div id="inner-header" className="wrap">
        <Logo isHomepage={isHomepage} />
        <PageNavigation />
      </div>
    </header>
    <div id="content">
      <div id="inner-content" className="wrap">
        <main
          id="main"
          itemScope=""
          itemProp="mainContentOfPage"
          itemType="http://schema.org/Blog"
        >
          {children}
        </main>
        <Sidebar />
      </div>
    </div>
    <Footer />
  </div>
);

export { Layout };
