import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Title from "../Title";
import "./Sidebar.scss";

const Sidebar = ({ posts }) => (
  <div id="sidebar1" className="sidebar" role="complementary">
    <div id="recent-posts" className="sidebar__item">
      <h2 className="sidebar__title">Recent Posts</h2>
      <ul>
        {posts.map(({ node }) => (
          <li key={node.slug}>
            <Link to={`/${node.slug}`}>
              <Title title={node.title} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const SidebarWithQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost(sort: { fields: date, order: DESC }, limit: 5) {
          edges {
            node {
              title {
                rendered
              }
              slug
            }
          }
        }
      }
    `}
    render={({ allWordpressPost }) => {
      const posts = allWordpressPost.edges;
      return <Sidebar posts={posts} />;
    }}
  />
);

export { SidebarWithQuery };
