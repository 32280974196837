import React from "react";
import "./EntryContent.scss";

const EntryContent = ({ children, itemProp = "" }) => (
  <section className="entry-content" itemProp={itemProp}>
    {children}
  </section>
);

export { EntryContent };
