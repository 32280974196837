import React from "react";
import Helmet from "react-helmet";

import { SITE_TITLE, TILE_COLOR, THEME_COLOR } from "../../config";

const HtmlHeader = ({ description = "" }) => (
  <Helmet
    title={SITE_TITLE}
    bodyAttributes={{
      itemscope: "",
      itemtype: "http://schema.org/WebPage"
    }}
  >
    <html lang="en-GB" />
    {Boolean(description) && <meta name="description" content={description} />}
    <meta name="HandheldFriendly" content="True" />
    <meta name="MobileOptimized" content="320" />
    <link rel="apple-touch-icon" href={`/images/apple-touch-icon.png`} />
    <link rel="icon" href={`/images/favicon.png`} />
    <meta name="msapplication-TileColor" content={TILE_COLOR} />
    <meta
      name="msapplication-TileImage"
      content={`/images/win8-tile-icon.png`}
    />
    <meta name="theme-color" content={THEME_COLOR} />
  </Helmet>
);

export { HtmlHeader };
