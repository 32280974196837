const AUTHOR = "Ryan Mitchell";
const SITE_TITLE = `Looseideas.co.uk - ${AUTHOR}`;
const POSTS_PER_PAGE = 10;
const URLS = {
  PAGINATION: "page",
  PAGINATION_ZERO: "/",
};

const TILE_COLOR = "#f01d4f";
const THEME_COLOR = "#121212";

module.exports = {
  AUTHOR,
  POSTS_PER_PAGE,
  SITE_TITLE,
  URLS,
  TILE_COLOR,
  THEME_COLOR,
};
